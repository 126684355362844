export class GlobalSettings {
  modulesActivation: ModulesActivation = new ModulesActivation();
  preventMultipleTabs: boolean = false;

  static clone(value: any) {
    const settings = new GlobalSettings();
    Object.assign(settings, value);
    return settings;
  }
}

export class ModulesActivation {
  purchasing: boolean = false;
  pointOfSale: boolean = false;
  invoicing: boolean = false;
  manufacturing: boolean = false;
  fixedAssets: boolean = false;
  moduleTransactions: ModuleTransactions = new ModuleTransactions();
}

export class ModuleTransactions {
  invoicingTransactions?: InvoicingTransactions;
}

export class OrganizationProfile {
  organizationName!: string;
  country!: number;
  taxRegistrationNumber?: string;
  commercialRegistrationNumber?: string;
  currency!: number;
  industry?: string;
  address?: any;
  businessDomain?: BusinessDomain;

  static clone(value: any) {
    const settings = new OrganizationProfile();
    Object.assign(settings, value);
    return settings;
  }
}

export class BusinessDomain {
  sectors: string[] = [];
  productLines: string[] = [];
  salesChannels: string[] = [];
  businessEngagement: string[] = [];
}

export class OrdersSetting {
  useSalesTax: boolean = true;
  fulfillmentMode: FulfillmentMode = FulfillmentMode.Automatic;
  automaticFulfillmentCondition: AutomaticFulfillmentCondition = AutomaticFulfillmentCondition.WhenOrderPaidAndDelivered;
  salesRecognition: SalesRecognitionSetting = SalesRecognitionSetting.Fulfilled;
  availableToSellFormula: AvailableToSellFormula = AvailableToSellFormula.OnHand_Refund_Orders_PreOrders
  paymentTerminal: boolean = false;

  static clone(value: any) {
    const settings = new OrdersSetting();
    Object.assign(settings, value);
    return settings;
  }
}

export class PurchaseInvoicesSetting {
  compoundDiscount: boolean = false;

  static clone(value: any) {
    const settings = new PurchaseInvoicesSetting();
    Object.assign(settings, value);
    return settings;
  }
}

export class SalesInvoicesSetting {
  defaultTaxId!: string;
  productPrices: InvoicingProductPrices = InvoicingProductPrices.Inclusive;
  einvoice_ksa_configuration?: EInvoiceKsaConfiguration

  static clone(value: any) {
    const settings = new SalesInvoicesSetting();
    Object.assign(settings, value);
    return settings;
  }
}

export interface EInvoiceKsaConfiguration {
  firstConnectionDate: Date
  connected: boolean;
  environment: string;
  invoiceType: string;
  otp: string;
  commonName: string;
  taxRegistrationNumber: string;
  branchName: string;
  organizationName: string;
  countryName: string;
  address: any;
  industry: string;
  serialNumber: string;
  CSID_PrivateKey: string;
  CSID_CSR: string;
  CSID_RequestID: number;
  CSID_TokenType: string;
  CSID_BinarySecurityToken: string;
  CSID_Secret: string;
  CSID_DispositionMessage: string;
  commercialRegistrationNumber: string;
  SubmissionData_Supplier_StreetName: string;
  SubmissionData_Supplier_CityName: string;
  SubmissionData_Supplier_CitySubdivisionName: string;
  SubmissionData_Supplier_BuildingNumber: string;
  SubmissionData_Supplier_PostalZone: string;
}

export class ProductsSetting {
  nameUnique: boolean = false;
  skuRequired: boolean = true;
  skuUnique: boolean = true;
  partNumberRequired: boolean = true;
  partNumberUnique: boolean = true;

  static clone(value: any) {
    const settings = new ProductsSetting();
    Object.assign(settings, value);
    return settings;
  }
}

export enum InvoicingTransactions {
  All = 1,
  SalesInvoices = 2,
  PurchaseInvoices = 3
}

export enum InvoicingProductPrices {
  Inclusive = "Inclusive",
  Exclusive = "Exclusive"
}

export enum SalesRecognitionSetting {
  Fulfilled = 'fulfilled',
  Delivered = 'delivered'
}

export enum FulfillmentMode {
  Manual = 1,
  Automatic = 2
}

export enum AutomaticFulfillmentCondition {
  WhenOrderPaidAndDelivered = 1,
  WhenOrderDelivered = 2
}

export enum AvailableToSellFormula {
  OnHand_Refund_Orders_PreOrders = 'OnHand_Refund_Orders_PreOrders',
  OnHand_Refund_Orders = 'OnHand_Refund_Orders'
}
