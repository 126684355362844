export enum RolesEnum {
    Admin = 'Admin',
    Cashier = 'Cashier',
    FixedAssets = 'FixedAssets',
    Manufacturing = 'Manufacturing',
    PWAUser = 'PWA User',
    Subscriber = 'Subscriber',
    SystemAdmin = 'System Admin',
    OrderTaker = 'Order Taker',
    OrderManager = 'Order Manager'
}
