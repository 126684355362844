@if (withHomeUrlNavigation) {
<a [routerLink]="['/']" id="btn_logo">
  <svg viewBox="0 0 533.27 104.93" class="logo" [style.width]="width">
    <path class="logo__emblem"
      d="M582.9,41.52h0a36.81,36.81,0,0,0-23.43-10.68,39,39,0,0,0-13.75-20.72A45,45,0,0,0,517.43.31a43.51,43.51,0,0,0-24,7.15,41.81,41.81,0,0,0-13.93,15.16c-.71,0-1.41-.06-2.11-.06a40.31,40.31,0,0,0-16,3.26,40.81,40.81,0,0,0-13,8.87,41.44,41.44,0,0,0,26.67,70.48l.74,0c.51,0,1,0,1.55,0l1.49,0,75.59,0h1.31a6.77,6.77,0,0,0,.89-.06,40.08,40.08,0,0,0,26-11.13,35.3,35.3,0,0,0,11-25.48C593.62,58,589.81,48.37,582.9,41.52ZM517.83,9.2c18.55,0,33.69,14.61,33.76,32.57a4.19,4.19,0,0,1-2.92,3.95,3.52,3.52,0,0,1-.39.16A45,45,0,0,1,533.8,48a70.12,70.12,0,0,1-17-2.21,4.21,4.21,0,0,1-2.65-2,4,4,0,0,1-.41-3.12,4.29,4.29,0,0,1,5.19-3c10,2.5,17.55,2.13,22.16,1.38l.2,0,.14,0,.19,0,.24,0,.1,0a1.08,1.08,0,0,0,.78-1v-.06a.28.28,0,0,0,0-.09.06.06,0,0,0,0,0v0l0-.14c-2.15-11.64-12.59-20.08-24.83-20.08-14,0-25.36,10.88-25.36,24.25S503.85,66,517.83,66A26.31,26.31,0,0,0,526,64.73,25.39,25.39,0,0,0,534.57,60a4.38,4.38,0,0,1,6,.37,4.08,4.08,0,0,1-.23,5.76l-.16.15a34.17,34.17,0,0,1-11.27,6.28l-24.13,9.33a1.64,1.64,0,0,1-.44.09,4.46,4.46,0,0,1-1.14.15,4.21,4.21,0,0,1-4.3-4.12,4.09,4.09,0,0,1,2.59-3.78l.08,0,4.7-1.82a34.12,34.12,0,0,1-14.63-10A32.2,32.2,0,0,1,486,52.89a31.14,31.14,0,0,1-2-11.14C483.94,23.8,499.14,9.2,517.83,9.2Zm37.95,87.34H478.65a33.84,33.84,0,0,1-20.88-7.35,33.2,33.2,0,0,1-11.58-18.32h0a29.89,29.89,0,0,1,0-14.67A34.59,34.59,0,0,1,472.74,31a26.56,26.56,0,0,1,3.87-.47,3.39,3.39,0,0,1,3.14,1.37,5.14,5.14,0,0,1,.55,5.23,1.71,1.71,0,0,1-.12.23,2.94,2.94,0,0,1-.95,1,4.4,4.4,0,0,1-1.78.65l-1.24.22c-.71.12-1.39.24-2,.37-10.8,2.16-17.68,11.17-19.51,18.63a21,21,0,0,0-.07,10.16v0c0,.1.07.22.1.36a24.79,24.79,0,0,0,24.15,19h76.93a20,20,0,0,0,19.9-20c0-10.86-7.24-19.23-17.22-19.9a6.34,6.34,0,0,1-.75-.07c-3.5-.73-4.18-5-2.06-7.29,1-1.05,1.63-1.47,3.68-1.34,6.45.42,11.5,3,16.91,8.5a28.76,28.76,0,0,1-20.44,48.85Z"
      transform="translate(-436.42 -0.3)"></path>
    <path class="logo__text"
      d="M692.81,69.37c0,1.51-.14,3.1-.21,4.39H638.81c1.65,14.19,11.52,21.17,23.25,21.17a32.11,32.11,0,0,0,21.1-8.13l6.27,8.06c-8.5,7.92-18,10.37-28.09,10.37-20.3,0-34.7-14.33-34.7-35.64,0-21,14.33-35.65,34-35.65C679.27,33.94,692.67,48.42,692.81,69.37ZM639,64.26h41.84c-1.44-12.1-9.29-19.87-20.38-19.87C648.31,44.39,640.75,52.59,639,64.26Z"
      transform="translate(-436.42 -0.3)"></path>
    <path class="logo__text"
      d="M757.43,44.6V.3h11.81V103.79H757.43V94.65c-5.4,6.4-13.39,10.58-24.12,10.58-19.23,0-34.2-15-34.2-35.64s15-35.65,34.2-35.65C744,33.94,752,38.12,757.43,44.6Zm-46.3,25c0,13.61,9.51,24.62,23.55,24.62,13.82,0,23.47-10.58,23.47-24.62S748.5,45,734.68,45C720.42,45,711.13,56,711.13,69.59Z"
      transform="translate(-436.42 -0.3)"></path>
    <path class="logo__text"
      d="M846.86,103.79H835.05V94.65c-5.4,6.4-13.4,10.58-24.12,10.58-19.23,0-34.21-15-34.21-35.64s15-35.65,34.21-35.65c10.72,0,18.72,4.18,24.12,10.66V35.38h11.81Zm-58.11-34.2c0,13.61,9.5,24.62,23.54,24.62,13.83,0,23.48-10.58,23.48-24.62S826.12,45,812.29,45C798,45,788.75,56,788.75,69.59Z"
      transform="translate(-436.42 -0.3)"></path>
    <path class="logo__text"
      d="M903.66,36.18l-2.88,11.59A26.72,26.72,0,0,0,889,45.11c-10.44,0-17.78,8-17.78,20.59v38.09H859.52V35.38h11.59v9a24.08,24.08,0,0,1,20-10.45A30.56,30.56,0,0,1,903.66,36.18Z"
      transform="translate(-436.42 -0.3)"></path>
    <path class="logo__text"
      d="M969.69,103.79H957.88V94.65c-5.4,6.4-13.39,10.58-24.12,10.58-19.23,0-34.21-15-34.21-35.64s15-35.65,34.21-35.65c10.73,0,18.72,4.18,24.12,10.66V35.38h11.81Zm-58.11-34.2c0,13.61,9.5,24.62,23.55,24.62,13.82,0,23.47-10.58,23.47-24.62S949,45,935.13,45C920.87,45,911.58,56,911.58,69.59Z"
      transform="translate(-436.42 -0.3)"></path>
  </svg>
</a>
}
@else {
<svg viewBox="0 0 533.27 104.93" class="logo" [style.width]="width">
  <path class="logo__emblem"
    d="M582.9,41.52h0a36.81,36.81,0,0,0-23.43-10.68,39,39,0,0,0-13.75-20.72A45,45,0,0,0,517.43.31a43.51,43.51,0,0,0-24,7.15,41.81,41.81,0,0,0-13.93,15.16c-.71,0-1.41-.06-2.11-.06a40.31,40.31,0,0,0-16,3.26,40.81,40.81,0,0,0-13,8.87,41.44,41.44,0,0,0,26.67,70.48l.74,0c.51,0,1,0,1.55,0l1.49,0,75.59,0h1.31a6.77,6.77,0,0,0,.89-.06,40.08,40.08,0,0,0,26-11.13,35.3,35.3,0,0,0,11-25.48C593.62,58,589.81,48.37,582.9,41.52ZM517.83,9.2c18.55,0,33.69,14.61,33.76,32.57a4.19,4.19,0,0,1-2.92,3.95,3.52,3.52,0,0,1-.39.16A45,45,0,0,1,533.8,48a70.12,70.12,0,0,1-17-2.21,4.21,4.21,0,0,1-2.65-2,4,4,0,0,1-.41-3.12,4.29,4.29,0,0,1,5.19-3c10,2.5,17.55,2.13,22.16,1.38l.2,0,.14,0,.19,0,.24,0,.1,0a1.08,1.08,0,0,0,.78-1v-.06a.28.28,0,0,0,0-.09.06.06,0,0,0,0,0v0l0-.14c-2.15-11.64-12.59-20.08-24.83-20.08-14,0-25.36,10.88-25.36,24.25S503.85,66,517.83,66A26.31,26.31,0,0,0,526,64.73,25.39,25.39,0,0,0,534.57,60a4.38,4.38,0,0,1,6,.37,4.08,4.08,0,0,1-.23,5.76l-.16.15a34.17,34.17,0,0,1-11.27,6.28l-24.13,9.33a1.64,1.64,0,0,1-.44.09,4.46,4.46,0,0,1-1.14.15,4.21,4.21,0,0,1-4.3-4.12,4.09,4.09,0,0,1,2.59-3.78l.08,0,4.7-1.82a34.12,34.12,0,0,1-14.63-10A32.2,32.2,0,0,1,486,52.89a31.14,31.14,0,0,1-2-11.14C483.94,23.8,499.14,9.2,517.83,9.2Zm37.95,87.34H478.65a33.84,33.84,0,0,1-20.88-7.35,33.2,33.2,0,0,1-11.58-18.32h0a29.89,29.89,0,0,1,0-14.67A34.59,34.59,0,0,1,472.74,31a26.56,26.56,0,0,1,3.87-.47,3.39,3.39,0,0,1,3.14,1.37,5.14,5.14,0,0,1,.55,5.23,1.71,1.71,0,0,1-.12.23,2.94,2.94,0,0,1-.95,1,4.4,4.4,0,0,1-1.78.65l-1.24.22c-.71.12-1.39.24-2,.37-10.8,2.16-17.68,11.17-19.51,18.63a21,21,0,0,0-.07,10.16v0c0,.1.07.22.1.36a24.79,24.79,0,0,0,24.15,19h76.93a20,20,0,0,0,19.9-20c0-10.86-7.24-19.23-17.22-19.9a6.34,6.34,0,0,1-.75-.07c-3.5-.73-4.18-5-2.06-7.29,1-1.05,1.63-1.47,3.68-1.34,6.45.42,11.5,3,16.91,8.5a28.76,28.76,0,0,1-20.44,48.85Z"
    transform="translate(-436.42 -0.3)"></path>
  <path class="logo__text"
    d="M692.81,69.37c0,1.51-.14,3.1-.21,4.39H638.81c1.65,14.19,11.52,21.17,23.25,21.17a32.11,32.11,0,0,0,21.1-8.13l6.27,8.06c-8.5,7.92-18,10.37-28.09,10.37-20.3,0-34.7-14.33-34.7-35.64,0-21,14.33-35.65,34-35.65C679.27,33.94,692.67,48.42,692.81,69.37ZM639,64.26h41.84c-1.44-12.1-9.29-19.87-20.38-19.87C648.31,44.39,640.75,52.59,639,64.26Z"
    transform="translate(-436.42 -0.3)"></path>
  <path class="logo__text"
    d="M757.43,44.6V.3h11.81V103.79H757.43V94.65c-5.4,6.4-13.39,10.58-24.12,10.58-19.23,0-34.2-15-34.2-35.64s15-35.65,34.2-35.65C744,33.94,752,38.12,757.43,44.6Zm-46.3,25c0,13.61,9.51,24.62,23.55,24.62,13.82,0,23.47-10.58,23.47-24.62S748.5,45,734.68,45C720.42,45,711.13,56,711.13,69.59Z"
    transform="translate(-436.42 -0.3)"></path>
  <path class="logo__text"
    d="M846.86,103.79H835.05V94.65c-5.4,6.4-13.4,10.58-24.12,10.58-19.23,0-34.21-15-34.21-35.64s15-35.65,34.21-35.65c10.72,0,18.72,4.18,24.12,10.66V35.38h11.81Zm-58.11-34.2c0,13.61,9.5,24.62,23.54,24.62,13.83,0,23.48-10.58,23.48-24.62S826.12,45,812.29,45C798,45,788.75,56,788.75,69.59Z"
    transform="translate(-436.42 -0.3)"></path>
  <path class="logo__text"
    d="M903.66,36.18l-2.88,11.59A26.72,26.72,0,0,0,889,45.11c-10.44,0-17.78,8-17.78,20.59v38.09H859.52V35.38h11.59v9a24.08,24.08,0,0,1,20-10.45A30.56,30.56,0,0,1,903.66,36.18Z"
    transform="translate(-436.42 -0.3)"></path>
  <path class="logo__text"
    d="M969.69,103.79H957.88V94.65c-5.4,6.4-13.39,10.58-24.12,10.58-19.23,0-34.21-15-34.21-35.64s15-35.65,34.21-35.65c10.73,0,18.72,4.18,24.12,10.66V35.38h11.81Zm-58.11-34.2c0,13.61,9.5,24.62,23.55,24.62,13.82,0,23.47-10.58,23.47-24.62S949,45,935.13,45C920.87,45,911.58,56,911.58,69.59Z"
    transform="translate(-436.42 -0.3)"></path>
</svg>
}