import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Pipe, PipeTransform } from '@angular/core';
import { agGridFilterTypes, dateAgGridOperators, defaultAgGridOperators, numberAgGridOperators, predefinedColumnsAgGridOperators } from '@shared/components/ag-grid-common-filter/ag-grid-common-filter.model';

@Pipe({
  name: 'agFilterColumnOperators'
})
export class AgFilterColumnOperatorsPipe implements PipeTransform {

  transform(columnDefs: (ColDef | ColGroupDef)[] | undefined, columnField: string = '') {
    let columnRef: ColDef | undefined;
    let operatorsList = [];
    if (columnDefs?.length) {
      columnRef = columnDefs.find((columnDef: ColDef) => columnDef.field === columnField)
    }

    if (columnRef?.filterParams?.hasPredefinedFilter) {
      return predefinedColumnsAgGridOperators;
    }

    switch (columnRef?.filter) {
      case agGridFilterTypes.date:
        operatorsList = dateAgGridOperators;
        break;
      case agGridFilterTypes.number:
        operatorsList = numberAgGridOperators;
        break;
      default:
        operatorsList = defaultAgGridOperators;
    }

    return operatorsList;
  }

}
