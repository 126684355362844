import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@auth';

export const rolesGuard: CanActivateFn = (route) => {
  const authService = inject(AuthService);
  const roles = route.data['roles'] || [];
  const matchAll = route.data['matchAll'] || false;
  if (authService.isAuthenticated()) {
    if (matchAll) {
      return roles.every((role: string) => authService.isInRole(role));
    } else {
      return roles.some((role: string) => authService.isInRole(role));
    }
  }
  return false;
};
