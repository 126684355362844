import { Component } from '@angular/core';

@Component({
  selector: 'app-multi-tabs-blocking-dialog',
  templateUrl: './multi-tabs-blocking-dialog.component.html',
  styleUrl: './multi-tabs-blocking-dialog.component.scss'
})
export class MultiTabsBlockingDialogComponent {

}
