import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[] = [], key: string, searchText: string): any[] {
    if (!items?.length || !key || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    return items.filter(item => item[key].toLowerCase().includes(searchText));
  }
}