import { Component, AfterViewInit, ViewChild, ChangeDetectorRef, AfterContentChecked, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { NavigationStart, Router } from '@angular/router';
import { PagePermissions } from 'src/app/shared/models/permissions';
import { RolesEnum } from 'src/app/shared/models/roles';
import { AppService } from '../../services/app.service';
import { AccountSettingsService } from '@core/services/account-settings.service';
import { HasPermissionsPipe } from '@shared/pipes/has-permissions.pipe';
import { HasPermissionPipe } from '@shared/pipes/has-permission.pipe';
import { LandingModulesNames, MenuItem, ModulePage, ModuleSideNavTabs, childrensMap } from '@shared/models/modules-landing.model';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IsInRolePipe } from '@shared/pipes/is-in-role.pipe';
import { environment } from 'src/environments/environment';
import { HelpersService, SystemSetting, SystemSettingsEnum } from '@shared';
import { Subscription } from 'rxjs';
import { PagesMenuService } from '@shared/services/pages-menu.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PageMenuDateService } from '@shared/services/page-menu-date.service';
import { Location } from '@angular/common';
import { SystemSettingsService } from '@core/services/system-settings.service';
import { GlobalSettings, InvoicingTransactions, ModulesActivation } from 'src/app/system/models/system-settings';
import { OnlineOfflineService } from '@core/services/online-offine.service';
import { AuthService } from '@auth';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  providers: [HasPermissionsPipe, HasPermissionPipe, IsInRolePipe],
  styleUrls: ['./nav-menu.component.scss'],
  animations: [
    trigger('heightAnimation', [
      state('*', style({
        height: '*',
        overflow: 'hidden',
      })),
      transition(':enter', [
        style({
          height: '0',
          overflow: 'hidden',
        }),
        animate('300ms cubic-bezier(0.68, -0.55, 0.27, 1.55)'),

      ]),
      transition(':leave', [
        animate('100ms cubic-bezier(0.68, -0.55, 0.27, 1.55)', style({
          height: '0',
          overflow: 'hidden',
        })),
      ]),
    ]),
  ]
})
export class NavMenuComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {

  modulesActivationSettings: ModulesActivation = new ModulesActivation();
  newSalesOrderLayout: boolean | null = false;
  shortcuts: ShortcutInput[] = [];
  bottomSheet!: MatBottomSheetRef;
  selectedMenuIndex: number = -1;
  menuItems: any[] = [];
  moduleSideNavTabs = ModuleSideNavTabs;

  public get pagePermissions(): typeof PagePermissions {
    return PagePermissions;
  }

  public get roles(): typeof RolesEnum {
    return RolesEnum;
  }

  get canViewSalesOrder() {
    const hasPermissions = this.hasPermissions.transform(this.pagePermissions.SalesOrders);
    if (!hasPermissions) {
      return false;
    }

    const rolesToIgnore: string[] = [RolesEnum.PWAUser, RolesEnum.Subscriber, RolesEnum.OrderManager];
    return this.authService.userRoles.some(role => !rolesToIgnore.includes(role));
  }

  private get _sideNavMenu(): any[] {
    return [
      {
        label: 'nav-menu.home',
        icon: 'home',
        routerLink: '/',
        link: '/home',
        primaryMenu: true,
        level2Location: 'below',
        module: LandingModulesNames.noModule,
        hasStarredPages: false,
        details: []
      },
      {
        label: 'nav-menu.purchasing',
        icon: 'shopping_cart',
        link: '/purchasing/',
        routerLink: "/purchasing/landing",
        id: 'purchasing',
        primaryMenu: true,
        level2Location: 'below',
        module: LandingModulesNames.purchasing,
        activeTab: ModuleSideNavTabs.starred,
        isHidden: !this.modulesActivationSettings.purchasing,
        hasStarredPages: false,
        details: [
          { id: "purchasing_purchaseRequests", label: 'nav-menu.purchaseRequests', description: "pages.purchaseRequestsDesc", routerLink: '/purchasing/purchase-requests', permissions: this.hasPermissions.transform(this.pagePermissions.PurchaseRequests), isStarred: false },
          { id: "purchasing_purchaseOrders", label: 'nav-menu.purchaseOrders', description: "pages.purchaseOrdersDesc", routerLink: '/purchasing/purchase-orders', permissions: this.hasPermissions.transform(this.pagePermissions.PurchaseOrders), isStarred: false },
          { id: "purchasing_products", label: 'nav-menu.products', description: "pages.PurchasingProductsDesc", routerLink: '/purchasing/products', permissions: this.hasPermissions.transform(this.pagePermissions.Products), isStarred: false },
          { id: "purchasing_suppliers", label: 'nav-menu.suppliers', description: "pages.PurchasingSuppliersDesc", routerLink: '/purchasing/suppliers', permissions: this.hasPermissions.transform(this.pagePermissions.Suppliers), isStarred: false },
          { id: "purchasing_locations", label: 'nav-menu.locations', description: "pages.InventoryLocationsDesc", routerLink: '/purchasing/locations', permissions: this.hasPermissions.transform(this.pagePermissions.Locations), isStarred: false }
        ]
      },
      {
        label: 'nav-menu.pos',
        icon: 'trending_up',
        link: '/pos/',
        routerLink: "/pos/landing",
        id: 'pos',
        primaryMenu: true,
        level2Location: 'below side-nav__sub--pos',
        module: LandingModulesNames.pos,
        activeTab: ModuleSideNavTabs.starred,
        isHidden: !this.modulesActivationSettings.pointOfSale,
        hasStarredPages: false,
        details: [
          { id: "pos_customers", checkForNewSalesLayout: "true", _newSalesOrderLayout: false, label: 'nav-menu.customers', description: "pages.POSCustomersDesc", routerLink: '/pos/customers', permissions: this.hasPermissions.transform(this.pagePermissions.Customers), isStarred: false },
          { id: "pos_customers", checkForNewSalesLayout: "true", _newSalesOrderLayout: true, label: 'nav-menu.customers', description: "pages.POSCustomersDesc", routerLink: '/pos/customers-v3', permissions: this.hasPermissions.transform(this.pagePermissions.Customers), isStarred: false },
          { id: "pos_salesOrders", label: 'nav-menu.salesOrders', description: "pages.POSOrdersDesc", routerLink: '/pos/sales-orders', checkForNewSalesLayout: "true", _newSalesOrderLayout: false, permissions: this.canViewSalesOrder, isStarred: false },
          { id: "pos_salesOrders", label: 'nav-menu.salesOrders', description: "pages.POSOrdersDesc", routerLink: '/pos/sales-orders-v3', checkForNewSalesLayout: "true", _newSalesOrderLayout: true, permissions: this.canViewSalesOrder, isStarred: false },
          { id: "pos_cashInSlashOut", label: 'nav-menu.cashInSlashOut', description: "pages.POSCashInOutDesc", routerLink: '/pos/cash-management', permissions: this.hasPermissions.transform(this.pagePermissions.CashRegisters), isStarred: false },
          { id: "pos_registerClosures", label: 'nav-menu.registerClosures', description: "pages.POSRegisterClosuresDesc", routerLink: '/pos/register-closures', permissions: this.isInRole.transform(this.roles.Admin) && this.hasPermissions.transform(this.pagePermissions.CashRegisters), isStarred: false },
          { id: "pos_products", label: 'nav-menu.products', description: "pages.POSProductsDesc", routerLink: '/pos/products', checkForNewSalesLayout: "true", _newSalesOrderLayout: true, permissions: this.hasPermissions.transform(this.pagePermissions.Products), isStarred: false },
          { id: "pos_serviceItems", label: 'nav-menu.serviceItems', description: "pages.POSServiceItemsDesc", routerLink: '/pos/service-items', checkForNewSalesLayout: "true", _newSalesOrderLayout: false, permissions: this.hasPermissions.transform(this.pagePermissions.ServiceItems), isStarred: false },
          { id: "pos_promotions", label: 'nav-menu.promotions', description: "pages.POSPromotionsDesc", routerLink: '/pos/promotions', permissions: this.hasPermissions.transform(this.pagePermissions.Promotions), isStarred: false },
          { id: "pos_cashRegisters", label: 'nav-menu.cashRegisters', description: "pages.POSCashRegisterDesc", routerLink: '/pos/cash-registers', permissions: this.isInRole.transform(this.roles.Admin) && this.hasPermissions.transform(this.pagePermissions.CashRegisters), isStarred: false }
        ]
      },
      {
        label: 'nav-menu.fixedAssets',
        icon: 'business',
        link: '/fixed-assets/',
        routerLink: "/fixed-assets/landing",
        id: 'fixedAssets',
        primaryMenu: false,
        level2Location: 'below',
        module: LandingModulesNames.fixedAssets,
        activeTab: ModuleSideNavTabs.starred,
        isHidden: !this.modulesActivationSettings.fixedAssets,
        hasStarredPages: false,
        details: [
          { id: "fixedAssets_assets", label: 'nav-menu.assets', description: "pages.FixedAssetsAssetsDesc", routerLink: '/fixed-assets/onboard', permissions: this.hasPermissions.transform(this.pagePermissions.FixedAssets), isStarred: false },
          { id: "fixedAssets_depreciation", label: 'nav-menu.depreciation', description: "pages.FixedAssetsDepreciationDesc", routerLink: '/fixed-assets/depreciation', permissions: this.hasPermissions.transform(this.pagePermissions.FixedAssetsDepreciation), isStarred: false }
        ]
      },
      {
        label: 'nav-menu.manufacturing',
        icon: 'build',
        link: '/manufacturing/',
        routerLink: "/manufacturing/landing",
        id: 'Manufacturing',
        primaryMenu: false,
        level2Location: 'below',
        module: LandingModulesNames.manufacturing,
        activeTab: ModuleSideNavTabs.starred,
        isHidden: !this.modulesActivationSettings.manufacturing,
        hasStarredPages: false,
        details: [
          { id: "Manufacturing_billOfMaterials", label: 'nav-menu.bom', description: "pages.ManufacturingBillOfMaterialsDesc", routerLink: '/manufacturing/bill-of-materials', permissions: this.hasPermissions.transform(this.pagePermissions.BillOfMaterials), isStarred: false },
          { id: "fixedAsManufacturing_productionOrdersepreciation", label: 'nav-menu.production', description: "pages.ManufacturingProductionOrdersDesc", routerLink: '/manufacturing/production-orders', permissions: this.hasPermissions.transform(this.pagePermissions.ProductionOrders), isStarred: false },
          { id: "Manufacturing_productionPlans", label: 'nav-menu.productionPlanning', description: "pages.ManufacturingProductionPlanningDesc", routerLink: '/manufacturing/production-planning', permissions: this.hasPermissions.transform(this.pagePermissions.ProductionPlans), isStarred: false }
        ]
      },
      {
        label: 'nav-menu.invoicing',
        icon: 'receipt_long',
        link: '/invoicing/',
        routerLink: "/invoicing/landing",
        id: 'Invoicing',
        primaryMenu: false,
        level2Location: 'below',
        module: LandingModulesNames.invoicing,
        activeTab: ModuleSideNavTabs.starred,
        isHidden: !this.modulesActivationSettings.invoicing,
        hasStarredPages: false,
        details: [
          { id: "Invoicing_purchaseInvoice", label: 'nav-menu.purchaseInvoice', description: "pages.InvoicingPurchaseInvoicesDesc", routerLink: '/invoicing/purchase-invoices', permissions: this.hasPermissions.transform(this.pagePermissions.PurchaseInvoices), transactionType: InvoicingTransactions.PurchaseInvoices, isStarred: false },
          { id: "Invoicing_salesInvoice", label: 'nav-menu.salesInvoice', description: "pages.InvoicingSalesInvoicesDesc", routerLink: '/invoicing/sales-invoices', permissions: this.hasPermissions.transform(this.pagePermissions.SalesInvoices), transactionType: InvoicingTransactions.SalesInvoices, isStarred: false },
          { id: "Invoicing_creditNote", label: 'nav-menu.creditNote', description: "pages.InvoicingCreditNotesDesc", routerLink: '/invoicing/credit-notes', permissions: this.hasPermissions.transform(this.pagePermissions.CreditNotes), transactionType: InvoicingTransactions.SalesInvoices, isStarred: false }
        ]
      },
      {
        label: 'nav-menu.data',
        icon: 'cloud',
        link: '/data/',
        routerLink: "/data/landing",
        id: "Data",
        primaryMenu: false,
        level2Location: 'below',
        module: LandingModulesNames.dataModule,
        activeTab: ModuleSideNavTabs.starred,
        hasStarredPages: false,
        details: [
          { id: "offline_sales_orders", label: 'nav-menu.offlineSalesOrders', description: "pages.DataOfflineSalesOrdersDesc", routerLink: '/data/offline-sales-orders', permissions: this.isInRole.transform(this.roles.Admin) || this.isInRole.transform(this.roles.OrderManager) && this.hasPermissions.transform(this.pagePermissions.SalesOrders), isStarred: false },
          { id: "potential_fraud_detector", label: 'nav-menu.potentialFraudDetector', description: "pages.DataPotentialFraudDetectorDesc", routerLink: '/data/potential-fraud-detector', permissions: this.isInRole.transform(this.roles.Admin), isStarred: false }
        ]
      },
      {
        label: 'nav-menu.system',
        icon: 'settings',
        link: '/system/',
        routerLink: "/system/landing",
        id: 'System',
        primaryMenu: true,
        level2Location: 'below',
        divider: true,
        module: LandingModulesNames.systemModule,
        activeTab: ModuleSideNavTabs.starred,
        hasStarredPages: false,
        details: [
          { id: "system_settings", label: 'nav-menu.settings', description: "pages.SystemSettingsDesc", routerLink: '/system/settings', permissions: this.isInRole.transform(this.roles.Admin) && this.hasPermissions.transform(this.pagePermissions.SystemSettings), isStarred: false },
          { id: "system_integrations", label: 'nav-menu.integration', description: "pages.SystemIntegrationDesc", routerLink: '/system/integrations', permissions: this.isInRole.transform(this.roles.Admin), isStarred: false }
        ]
      },
      {
        label: 'nav-menu.samples',
        icon: 'palette',
        link: '/samples/',
        primaryMenu: false,
        level2Location: 'middle',
        divider: true,
        module: LandingModulesNames.samples,
        activeTab: ModuleSideNavTabs.starred,
        isHidden: environment.name !== 'dev',
        hasStarredPages: false,
        details: [
          { id: "", label: 'Buttons', routerLink: '/samples/buttons', permissions: true, isStarred: false },
          { id: "", label: 'Typo', routerLink: '/samples/typo', permissions: true, isStarred: false },
          { id: "", label: 'Elevations', routerLink: '/samples/elevations', permissions: true, isStarred: false },
          { id: "", label: 'Forms', routerLink: '/samples/forms', permissions: true, isStarred: false },
          { id: "", label: 'Dialogs', routerLink: '/samples/dialogs', permissions: true, isStarred: false },
          { id: "", label: 'Bottom sheet', routerLink: '/samples/bottom-sheet', permissions: true, isStarred: false },
          { id: "", label: 'Cards', routerLink: '/samples/cards', permissions: true, isStarred: false },
          { id: "", label: 'Menu', routerLink: '/samples/menu', permissions: true, isStarred: false },
          { id: "", label: 'Sliders', routerLink: '/samples/sliders', permissions: true, isStarred: false },
          { id: "", label: 'Segmented buttons', routerLink: '/samples/segmented-buttons', permissions: true, isStarred: false },
          { id: "", label: 'Fab', routerLink: '/samples/fab', permissions: true, isStarred: false },
          { id: "", label: 'Snackbar', routerLink: '/samples/snackbar', permissions: true, isStarred: false },
          { id: "", label: 'Progress', routerLink: '/samples/progress', permissions: true, isStarred: false },
          { id: "", label: 'Symbols', routerLink: '/samples/symbols', permissions: true, isStarred: false },
          { id: "", label: 'Divider', routerLink: '/samples/divider', permissions: true, isStarred: false },
          { id: "", label: 'Badges', routerLink: '/samples/badges', permissions: true, isStarred: false },
          { id: "", label: 'Date picker', routerLink: '/samples/date-picker', permissions: true, isStarred: false },
          { id: "", label: 'Lists', routerLink: '/samples/lists', permissions: true, isStarred: false },
          { id: "", label: 'Navigation drawer', routerLink: '/samples/navigation-drawer', permissions: true, isStarred: false },
          { id: "", label: 'Navigation rails', routerLink: '/samples/navigation-rails', permissions: true, isStarred: false }
        ]
      }
    ];
  }

  private modulesActivationChangeSubscription?: Subscription;
  private layoutChangeSubscription?: Subscription;

  @ViewChild('moreMenuDialog', { static: false }) moreMenuDialog!: TemplateRef<any>;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    public helpersService: HelpersService,
    public appService: AppService,
    private hasPermissions: HasPermissionsPipe,
    private isInRole: IsInRolePipe,
    private _bottomSheet: MatBottomSheet,
    public menuService: PagesMenuService,
    private location: Location,
    private pageMenuDateService: PageMenuDateService,
    private accountSettingsService: AccountSettingsService,
    private systemSettingService: SystemSettingsService,
    private onlineOfflineService: OnlineOfflineService,
    private authService: AuthService
  ) { }


  ngOnInit(): void {
    this.loadAccountSettings();
    this.loadModulesActivationSettings();

    this.menuService.starredPages$.subscribe((res) => {
      this.menuItemsMapper();
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.updateLatestOpendByRouterLink(event.url);
      }
    });
  }

  ngAfterViewInit() {
    this.renderNavMenu();
    this.initShortcuts();

    this.layoutChangeSubscription = this.accountSettingsService.salesOrderLayoutChanged$.subscribe({
      next: res => {
        this.newSalesOrderLayout = res;
        this.menuService.navMenuState.newSalesOrderLayout = res;
        this.menuItemsMapper();
      }
    });
    this.modulesActivationChangeSubscription = this.systemSettingService.modulesActivationSettingsChanged$.subscribe({
      next: res => {
        this.modulesActivationSettings = res;
        this.renderNavMenu();
      }
    });
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.modulesActivationChangeSubscription?.unsubscribe();
    this.layoutChangeSubscription?.unsubscribe();
  }

  initShortcuts() {
    this.shortcuts.push(
      {
        key: ['g h'],
        label: 'Navigation',
        description: 'Go to Home',
        command: () => this.router.navigate(['/']),
        preventDefault: true
      },
      {
        key: ['n v', 'g v'],
        label: 'Navigation',
        description: 'Go to new Service Item',
        command: () => this.router.navigate(['/pos/service-item/new']),
        preventDefault: true
      },
      {
        key: ['n s', 'g s'],
        label: 'Navigation',
        description: 'Go to new Sales Order',
        command: () => this.goToNewSalesOrder(),
        preventDefault: true
      }
    );
  }

  isActiveRoute(route: string): boolean {
    if (route === '/home' && this.router.url === '/')
      return true
    else
      return this.router.url.includes(route);
  }

  changeTheme() {
    if (this.appService.state.theme === 'light')
      this.appService.changeTheme('dark');
    else
      this.appService.changeTheme('light');
  }

  openMoreMenu() {
    this.selectedMenuIndex = -1;
    this.bottomSheet = this._bottomSheet.open(this.moreMenuDialog, {
      panelClass: 'nav-bottom-sheet'
    });
  }

  routeClick() {
    setTimeout(() => {
      this.menuService.navMenuState.selectedMenuIndex = -1;
    }, 100);
    if (this.bottomSheet)
      this.bottomSheet.dismiss()
  }

  private renderNavMenu() {
    setTimeout(() => {
      this.menuService.menu_items = this._sideNavMenu.filter(item => !item.isHidden && (!item.details.length || !!item.details.find((subItem: any) => subItem.permissions)));

      // Filter invoicing items based on invoicingTransactions
      if (this.modulesActivationSettings.invoicing && this.modulesActivationSettings.moduleTransactions?.invoicingTransactions) {
        const invoicingItems = this.menuService.menu_items.find(item => item.id === 'Invoicing');
        invoicingItems.details = invoicingItems.details.filter((subItem: any) => {
          if (this.modulesActivationSettings.moduleTransactions.invoicingTransactions === InvoicingTransactions.All) {
            return true;
          } else {
            return subItem.transactionType === this.modulesActivationSettings.moduleTransactions.invoicingTransactions;
          }
        });
      }
    });
  }

  private async loadAccountSettings() {
    const accountSettings = await this.accountSettingsService.loadAccountSettings();
    this.newSalesOrderLayout = accountSettings?.newSalesOrderLayout ?? false;
    this.menuService.navMenuState.newSalesOrderLayout = accountSettings?.newSalesOrderLayout ?? false;
    this.menuItemsMapper();
  }

  private async loadModulesActivationSettings() {
    let response: SystemSetting | undefined;
    if (this.onlineOfflineService.isOnline) {
      response = await this.systemSettingService.getModulesBasedOnPricingPlan();
    }
    else {
      response = await this.systemSettingService.getSystemSettingByNameLocalAsync(SystemSettingsEnum.Global);
    }
    const globalSettings = response ? GlobalSettings.clone(JSON.parse(response.value)) : new GlobalSettings();
    this.modulesActivationSettings = globalSettings.modulesActivation;
    this.renderNavMenu();
  }

  private goToNewSalesOrder() {
    if (this.newSalesOrderLayout)
      this.router.navigate(['/pos/sales-order-v3/new']);
    else
      this.router.navigate(['/pos/sales-order/new']);
  }

  toggleStarred(data: ModulePage, moduleItem: MenuItem) {
    const starredPage = this.menuService.starredPages.find((starredPage: ModulePage) => starredPage.routerLink === data.routerLink);
    const isStarred = starredPage ? false : true;
    if (starredPage) {
      data = { ...data, ...starredPage, module: moduleItem.module };
    }
    // if(moduleItem?.starredCount === 1)  {
    //   (moduleItem.activeTab = ModuleSideNavTabs.starred)
    //   moduleItem.activeTabDone = true;
    // };

    this.menuService.onToggleStarred(isStarred, data, moduleItem);
  }

  menuItemsMapper() {
    this.menuItems = this.menuService.menuItemsMapper([...this.menuService.menu_items]);
  }

  updateLatestOpendByRouterLink(url: string = this.location.path()) {
    const modulePages: ModulePage[] = childrensMap(this.menuService.menu_items);
    const currentUrlWithoutParams: string = `${url}`.split('?')[0];
    const pageDetails = modulePages.find((el: ModulePage) => el.routerLink === currentUrlWithoutParams);
    if (pageDetails) {
      this.pageMenuDateService.getClickedDate(pageDetails.id);
    }
  }
}
