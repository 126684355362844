import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { AppSettingsService } from './app-settings.service';
import { ActionType, EntityType, NotificationSource, SignalRNotification } from '@core/models/notification-hub';
import { DexieDbProvider, StockItem, WarehouseStock } from '@shared';
import { HttpClient } from '@angular/common/http';
import Dexie from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private _edaraCoreApiBaseUrl: string;
  private hubConnection?: signalR.HubConnection;
  private _tenantId!: string;

  // Observable to broadcast messages to components
  private notificationSubject = new Subject<SignalRNotification<any> | null>();
  currentNotification$: Observable<SignalRNotification<any> | null> = this.notificationSubject.asObservable();

  private readonly entityApiEndpoints: Record<EntityType, string> = {
    [EntityType.PurchaseOrder]: '/api/PurchaseOrders/',
    [EntityType.Stockltem]: ''
  };

  private readonly dexieDbTableMapping: Record<EntityType, string> = {
    [EntityType.PurchaseOrder]: 'PRCH_PurchaseOrders',
    [EntityType.Stockltem]: ''
  };

  constructor(private httpClient: HttpClient, private localDbProvider: DexieDbProvider) {
    this._edaraCoreApiBaseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this._edaraCoreApiBaseUrl.endsWith('/')) {
      this._edaraCoreApiBaseUrl = this._edaraCoreApiBaseUrl.slice(0, -1);
    }
  }

  // Connect to SignalR hub
  public startConnection(tenantId: string): void {
    this._tenantId = tenantId;
    const url = `${this._edaraCoreApiBaseUrl}/notificationshub`;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        withCredentials: false
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.onreconnected(() => this.joinGroup(this._tenantId));

    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started.');
        this.joinGroup(this._tenantId);
      })
      .catch(err => console.error('Error while starting connection:', err));

    this.hubConnection.on('ReceiveNotification', (data: SignalRNotification) => {
      if(data.notificationSource === NotificationSource.Edara3Backend){
        this.processIncomingSyncNotification(data);
      }
      if(data.notificationSource === NotificationSource.Edara2_5_Edara2_Connector && data.entityType === EntityType.Stockltem){
        this.handleStockitemBalanceUpdate(data);
      }
    });
  }

  // Method to join a group by tenant ID
  public joinGroup(tenantId: string): void {
    this._tenantId = tenantId;
    this.hubConnection?.invoke('JoinGroup', tenantId)
      .then(() => console.log(`Joined group: ${tenantId}`))
      .catch(err => console.error('Error while joining group:', err));
  }

  // Method to leave a group by tenant ID
  public leaveGroup(tenantId: string): void {
    this.hubConnection?.invoke('LeaveGroup', tenantId)
      .then(() => console.log(`Left group: ${tenantId}`))
      .catch(err => console.error('Error while leaving group:', err));
  }

  private processIncomingSyncNotification(notification: SignalRNotification) {
    switch (notification.actionType) {
      case ActionType.Create:
      case ActionType.Update:
        this.handleCreateOrUpdate(notification);
        break;
      case ActionType.Delete:
        this.handleDelete(notification);
        break;
      default:
        console.warn(`Unhandled action type: ${notification.actionType}`);
    }
  }

  private handleCreateOrUpdate(notification: SignalRNotification) {
    // const tableName = this.dexieDbTableMapping[notification.entityType];
    // if (!tableName) {
    //   console.warn(`No local DB table found for entity type: ${notification.entityType}`);
    //   return;
    // }

    const url = `${this._edaraCoreApiBaseUrl}${this.entityApiEndpoints[notification.entityType]}${notification.entityId}`;
    this.httpClient.get(url).subscribe({
      next: (data) => {
        // this.localDbProvider.db[tableName].put(data)
        //   .then(() => this.notificationSubject.next(notification))
        //   .catch((err: any) => this.handleLocalDatabaseError(err, 'updating'));
        notification.data = data;
        this.notificationSubject.next(notification);
      },
      error: (error) => {
        console.error('Error fetching entity:', error);
      }
    });
  }

  private handleDelete(notification: SignalRNotification) {
    // const tableName = this.dexieDbTableMapping[notification.entityType];
    // if (!tableName) {
    //   console.warn(`No local DB table found for entity type: ${notification.entityType}`);
    //   return;
    // }

    // this.localDbProvider.db[tableName].delete(notification.entityId)
    //   .then(() => this.notificationSubject.next(notification))
    //   .catch((err: any) => this.handleLocalDatabaseError(err, 'deleting'));
    this.notificationSubject.next(notification);
  }

  private handleLocalDatabaseError(error: any, operation: string) {
    console.error(`Error ${operation} entity in IndexedDB:`, error);
    this.localDbProvider.handleErrors(error);
  }

  private async handleStockitemBalanceUpdate(data: SignalRNotification) {
    const table = (this.localDbProvider.db as Dexie).table('stockItems');
    const stockItem: StockItem = await table.get(data.entityId);

    const currentStockItemBalances = stockItem.warehouse_stocks ?? [];
    const newStockItemBalances = data.extraAttributes['StockItemBalances'] as any[] ?? [];

    for (const balance of newStockItemBalances) {
      const existingBalance = currentStockItemBalances.find(x =>
        x.batch_number === balance.batch_number &&
        x.expiration_date === balance.expiration_date &&
        x.warehouse_id === balance.warehouse_id &&
        x.stock_item_id === balance.stock_item_id
      );

      if(existingBalance){
        existingBalance.balance = balance.balance;
        existingBalance.reserved_balance = balance.reserved_balance;
      } else {
        currentStockItemBalances.push(balance as WarehouseStock);
      }
    }

    stockItem.warehouse_stocks = currentStockItemBalances;
    table.put(stockItem);
  }

}
