import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'listTranslate',
    pure: true
})
export class ListTranslatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) { }

    transform(
        values: string[] = [],
        options: {
            outputSeparator?: string;      // Output separator (default: ', ')
            translateFileLookup?: string; // Translate file to use (default: 'en')
        } = {}
    ): string {
        if (!values || values.length === 0) {
            return '';
        }

        const { outputSeparator = ', ', translateFileLookup = 'en' } = options;

        const translations = this.translateService.store.translations[translateFileLookup];
        if (!translations) {
            return values.join(outputSeparator);
        }

        // Reverse map: value -> key for nested structures
        const reverseMap = this.createReverseMap(translations);

        return values
            .map(value => {
                const trimmedValue = value.trim();
                const key = reverseMap[trimmedValue]; // Find the key for this value
                const translated = key ? this.translateService.instant(key) : trimmedValue;
                return translated;
            })
            .join(outputSeparator);
    }

    /**
     * Recursively creates a reverse map of translations (value -> key).
     * @param translations Object containing the translations for the current language.
     * @param currentPath The current path in the nested object.
     */
    private createReverseMap(translations: any, currentPath: string = ''): Record<string, string> {
        const reverseMap: Record<string, string> = {};

        for (const key in translations) {
            if (translations.hasOwnProperty(key)) {
                const value = translations[key];
                const fullPath = currentPath ? `${currentPath}.${key}` : key;

                if (typeof value === 'object' && value !== null) {
                    // Recursively handle nested objects
                    Object.assign(reverseMap, this.createReverseMap(value, fullPath));
                } else {
                    reverseMap[value] = fullPath;
                }
            }
        }

        return reverseMap;
    }
}
