export interface SystemSetting {
    id?: string;
    name: string;
    value: string;
}

export enum SystemSettingsEnum {
    OfflineModeEnabled = 'OfflineModeEnabled',
    OfflineModeExcludedUsers = 'Sales.OfflineModeExcludedUsers',
    HideSubTotal = 'HideSubTotal',
    SystemStartDate = 'FixedAssets.SystemStartDate',
    FinancialYearEndDate = "FixedAssets.FinancialYearEndDate",
    AllowSellingBySerialNumber = "Sales.AllowSellingBySerialNumber",
    CheckSerialOwner = "Sales.CheckSerialOwner",
    CheckSerialAvailability = "Sales.CheckSerialAvailability",
    CheckSerialFulfillment = "Sales.CheckSerialFulfillment",
    InventoryManagementMethod = "Warehouse.InventoryManagementMethod",
    HideBalanceAndBatchDetails = "Sales.HideBalanceAndBatchDetails",
    UiControlToFocusOnAfterAddingItem = "Sales.UiControlToFocusOnAfterAddingItem",
    PrioritizesScanningBarcode = "Sales.PrioritizesScanningBarcode",
    AllowRepeatingProductInPO = "Purchasing.AllowRepeatingProductInPO",
    AllowLineItemWithZeroPriceInPO = "Purchasing.AllowLineItemWithZeroPriceInPO",
    PreventSellingZeroBalanceStockItems = "Sales.PreventSellingZeroBalanceStockItems",
    HideCostOnProductionOrderPrint = "Manufacturing.HideCostOnProductionOrderPrint",
    OrderTakerUsers = "Sales.OrderTakerUsers",
    UseCashOnDelivery = "Sales.UseCashOnDelivery",
    RealTimeStockItemBalanceUpdate = "Sales.RealTimeStockItemBalanceUpdate",
    DefaultCurrency = "DefaultCurrency",
    // Settings V3
    EdaraSetupCompleted = "EdaraSetupCompleted",
    Global = "Global",
    OrganizationProfile = "OrganizationProfile",
    Order = "Order",
    PurchaseInvoice = "PurchaseInvoice",
    SalesInvoice = "SalesInvoice",
    Product = "Product",
    EReceipt_EGP_Connected = "EReceipt_EGP_Connected",
    EReceipt_EGP_Paused = "EReceipt_EGP_Paused"
}


export enum UiControlToFocusOnAfterAddingItemEnum {
    SearchInput = 'SearchInput',
    ItemQuantity = 'ItemQuantity'
}

export enum PrioritizesScanningBarcodeEnum {
    normalOverWeighted = 1,
    weightedOverNormal = 2
}

export enum ParentModuleEnum {
    PointOfSale = 'PointOfSale',
    Invoicing = 'Invoicing',
    Purchasing = 'Purchasing',
    FixedAssets = 'FixedAssets',
    Manufacturing = 'Manufacturing'
}
