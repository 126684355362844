<div class="text-center">
    <icon name="select_window" svgFill="var(--m3-sys-error)" width="90" height="90" class="mb-4"></icon>
    <h2 class="typo-title mb-2">
        {{ "settingsV3.global.preventMultiTab.blockScreenHeader" | translate }}
    </h2>
    <h4 class="typo-title-medium font-regular">
        {{ "settingsV3.global.preventMultiTab.blockScreenMsg" | translate }}
    </h4>
    <button id="btn_multiTabsBlockingGotIt" mat-flat-button mat-dialog-close type="button" color="primary"
        class="px-5 mt-4">
        {{'buttons.gotIt' | translate }}
    </button>
</div>